<template>
  <div class="download">
    <img :src="require('@/assets/images/index_home/failimg.png')" alt="" class="fail"/>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style lang="less" scoped>
.download{
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    .fail{
        width: 100%;
    }
}
</style>
